import { computed, ref } from 'vue'

import { navigationAPI } from '@backmarket/http-api'
import { useHttpFetch } from '@backmarket/nuxt-module-http/useHttpFetch'

export async function useProductListSideNavigation(id: string) {
  const isExpanded = ref(false)

  const { data, error } = await useHttpFetch(
    navigationAPI.getProductListNavigation,
    {
      queryParams: { id },
    },
  )

  const parent = computed(() => {
    if (!data.value || error.value) {
      return []
    }

    return data.value.parent_nav
  })
  const children = computed(() => {
    if (!data.value || error.value) {
      return []
    }

    return data.value.children
  })

  const MIN = 5

  const visibleChildren = computed(() =>
    isExpanded.value ? children.value : children.value.slice(0, MIN),
  )

  const isExpandable = computed(() => children.value.length > MIN)

  function toggle() {
    isExpanded.value = !isExpanded.value
  }

  return {
    parent,
    visibleChildren,
    children,
    isExpanded,
    toggle,
    isExpandable,
  }
}
